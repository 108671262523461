/* Rich text overrides */

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
    min-height: 50px;
}

.public-DraftEditorPlaceholder-inner {
    color: #BFBFBF;
}

/* Custom Global Styles */

.fade-to-white-at-bottom:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 1) 80%);
    width: 100%;
    height: 6rem;
}

.shadow-border {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

/* Animations */

.grow-on-hover {
    width: 10rem;
    transition: all 1s;
}

.grow-on-hover:hover {
    width: 100%;
}

.grow-on-hover.span:last-of-type {
    font-size: 1rem
}

.grow-on-hover.span:last-of-type:hover {
    font-size: 3rem
}

.show-on-hover {
    display: none;
    transition: all 1s;
}

.show-on-hover:hover {
    display: block;
}

.fade-in {
    animation: fade-in 0.5s ease-in-out;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

.highlight-light-blue-and-fade {
    animation: nav-highlight-light-blue 5s ease;
}

@keyframes nav-highlight-light-blue {
    0% {
        background-color: #d6e6ff;
    }

    80% {
        background-color: #d6e6ff;
    }

    100% {
        background-color: transparent;
    }
}

.pulsing-animation {
    transform: scale(1);
    animation: pulse-black 1.5s infinite;
}

@keyframes pulse-black {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}